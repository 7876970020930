<template>
  <el-dialog
    :visible.sync="showDialog"
    width="360px"
    custom-class="confirmTipsDialog"
    :top="'0'"
    :destroy-on-close="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    :before-close="close"
  >
    <div class="dialogContent">
      <div class="dialogText">
        <img
          :src="
            require('../../../../assets/images/teamManagement/success_icon.png')
          "
        />
        <div class="text" v-html="showText"></div>
      </div>
      <div class="dialogButton">
        <div
          class="buttonStyle"
          v-waves
          @click.stop="activateEmailService"
          :style="{ background: buttonColor }"
        >
          <i class="iconfont_Me icon-check iconStyle"></i>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "ConfirmTipsDialog",
  computed: {
    confirmTipsDialog() {
      return this.$store.state.dialog.confirmTipsDialog;
    },
    // 是否显示弹窗
    showDialog() {
      return this.confirmTipsDialog ? this.confirmTipsDialog.showDialog : false;
    },
    // 显示内容
    showText() {
      return this.confirmTipsDialog ? this.confirmTipsDialog.showText : "";
    },
    // 按钮颜色
    buttonColor() {
      return this.confirmTipsDialog && this.confirmTipsDialog.color
        ? this.confirmTipsDialog.color
        : "#52BD68";
    },
    // 确认回调
    confirmFn() {
      return this.confirmTipsDialog && this.confirmTipsDialog.confirmFn
        ? this.confirmTipsDialog.confirmFn
        : null;
    },
    // 关闭回调
    closeFn() {
      return this.confirmTipsDialog && this.confirmTipsDialog.closeFn
        ? this.confirmTipsDialog.closeFn
        : null;
    },
  },
  methods: {
    activateEmailService() {
      // 执行回调
      if (this.confirmFn) {
        this.confirmFn();
      }
      this.close();
    },
    close() {
      if (this.closeFn) {
        this.closeFn();
      }
      this.$store.commit("setConfirmTipsDialog", null);
    },
  },
};
</script>
<style lang="stylus">
.confirmTipsDialog
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  .el-dialog__header
    flex-shrink: 0;
    width: 100%;
  .el-dialog__body
    padding: 0;
    flex: 1;
    width: 100%;
    overflow: hidden;
    .dialogContent
      width: 100%;
      height: 100%;
      padding-top: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .dialogText
        width: 100%;
        font-size: 20px;
        padding: 0 46px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        img
          width: 28px;
          height: 28px;
          vertical-align: middle;
          flex-shrink: 0;
        .text
          flex: 1;
          color: #292D32;
          line-height: 28px;
          font-size: 20px;
          vertical-align: middle;
          padding-left: 10px;
          word-break: break-word;
      .dialogButton
        padding: 0 24px 24px 24px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
       .buttonStyle
          width 100%;
          height 48px;
          font-size 16px;
          color #333333;
          border-radius 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .iconStyle
            color: #FFFFFF;
            font-size: 24px;
</style>
