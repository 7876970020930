var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.showDialog,
        width: "360px",
        "custom-class": "confirmTipsDialog",
        top: "0",
        "destroy-on-close": true,
        "append-to-body": true,
        "close-on-click-modal": false,
        "before-close": _vm.close
      },
      on: {
        "update:visible": function($event) {
          _vm.showDialog = $event
        }
      }
    },
    [
      _c("div", { staticClass: "dialogContent" }, [
        _c("div", { staticClass: "dialogText" }, [
          _c("img", {
            attrs: {
              src: require("../../../../assets/images/teamManagement/success_icon.png")
            }
          }),
          _c("div", {
            staticClass: "text",
            domProps: { innerHTML: _vm._s(_vm.showText) }
          })
        ]),
        _c("div", { staticClass: "dialogButton" }, [
          _c(
            "div",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "buttonStyle",
              style: { background: _vm.buttonColor },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.activateEmailService.apply(null, arguments)
                }
              }
            },
            [_c("i", { staticClass: "iconfont_Me icon-check iconStyle" })]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }